.text-line-height-1 {
  color: #FFF;
  font-size: 19.941px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-line-height-2 {
  color: #FFF;
  font-size: 19.941px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.promo-classes .membership-container .membership-image-2 {
  position: relative;
  top: -10px;
  /* left: 50px; */
  width: 50%;
  transform: scale(1.0);
}

.promo-classes .membership-container .daniblifting-image {
  max-width: 320px;
}

@media only screen and (min-width: 300px) and (max-width: 399px) {

  /* styles here will be applied when the screen width is between 400px and 500px */
  .align-list-box {
    margin: 0px 5px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  .align-list-box {
    margin: 0px 24px;
  }
}

@media screen and (max-width: 550px) {
  .membership-sub-title {
    font-size: 14px;
    line-height: 25px;
  }

  .promo-classes .membership-container .daniblifting-image {
    max-width: 250px;
  }
}

@media screen and (min-width:1024px) {
  .promo-classes .membership-title {
    font-size: 22px !important;
    font-weight: 600;
    line-height: 54px;
  }

  .promo-classes .membership-sub-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
  }

  .promo-classes .membership-container .membership-image {
    position: relative;
    top: -10px;
    /* left: 50px; */
    transform: scale(1.3);
  }

}

@media screen and (min-width:1100px) {
  .promo-classes .membership-title {
    font-size: 28px !important;
    font-weight: 600;
    line-height: 54px;
  }

  .promo-classes .membership-sub-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
  }

  .promo-classes .membership-container .membership-image {
    position: relative;
    top: -40px;
    left: 100px;
    transform: scale(1);
  }

}

@media only screen and (min-width: 760px) and (max-width: 780px) {
  .promo-classes .membership-title {
    font-size: 22px !important;
    font-weight: 600;
    line-height: 54px;
  }

  .promo-classes .membership-sub-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
  }

  .promo-classes .membership-container .membership-image {
    position: relative;
    top: 50px;
    transform: scale(1.4);
  }

}

@media (max-width: 780px) {

  .text-line-height-1 {
    color: #FFF;
    text-align: center;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .text-line-height-2 {
    color: #FFF;
    text-align: center;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .listing-with-icon {
    line-height: 10px !important;
  }

}


.promotion-page-wrapper {
  min-height: 100vh;
  /* Plan 2 if bg is not working */
  /* background: url('./../../Assets/images/fullPageBg.png');  */
  /* background-attachment: fixed;
  background-position: 50% 0;
  background-size: cover; */
}

/* .promotion-page-wrapper.mhst {
  background-position: center bottom;
} */

.promotion-page-wrapper .advertise-img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.promotion-page-wrapper .mhst-img {
  object-position: center left;
}

.promotion-page-wrapper .qcm-img {
  object-position: top right;
}

.promotion-page-wrapper .daniblifting-img,
.promotion-page-wrapper .pedim-img {
  object-position: top right;
}

.promotion-page-wrapper .jsterling-img,
.promotion-page-wrapper .sleep-care-img {
  object-position: center right;
}

.promotion-page-wrapper .content-wrapper {
  padding-left: 60px !important;
}

.promotion-page-wrapper .sub-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  margin: 0;
}

.promotion-page-wrapper .sub-title-h4 {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
}

.promotion-page-wrapper .listing-with-icon {
  gap: 5px !important;
}

.promotion-page-wrapper .listing-with-icon li {
  font-size: 16px !important;
  font-weight: 300;
}

.promotion-page-wrapper .listing-with-icon li img {
  width: 16px !important;
}

.promotion-page-wrapper .continue-btn {
  background: #EFB729;
  border-color: #EFB729;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
  padding: 10px 25px;
  color: #21173A !important;
}

@media only screen and (max-width: 1400px) {

  .promotion-page-wrapper .sub-title {
    font-size: 17px;
  }

  .promotion-page-wrapper .sub-title-h4 {
    font-size: 15px;
  }

  .promotion-page-wrapper .listing-with-icon li {
    font-size: 14px !important;
    line-height: 1.3;
  }

  .promotion-page-wrapper .listing-with-icon li img {
    width: 15px !important;
  }

  .promotion-page-wrapper .continue-btn {
    font-size: 14px;
    padding: 10px 25px;
  }

}

/* @media only screen and (max-width: 1200px) {

  .promotion-page-wrapper .title {
    font-size: 18px;
  }

  .promotion-page-wrapper .sub-title {
    font-size: 18px;
  }

  .promotion-page-wrapper .sub-title-h4 {
    font-size: 16px;
  }

  .promotion-page-wrapper .listing-with-icon li {
    font-size: 16px !important;
  }

  .promotion-page-wrapper .listing-with-icon li img {
    width: 16px !important;
  }

  .promotion-page-wrapper .content-wrapper {
    padding-left: 11% !important;
  }

} */

@media (max-width: 991px) {

  .promotion-page-wrapper .sub-title {
    font-size: 16px;
  }

  .promotion-page-wrapper .sub-title-h4 {
    font-size: 14px;
  }

  .promotion-page-wrapper .listing-with-icon li {
    font-size: 13px !important;
    line-height: 1.2;
    align-items: start;
    text-align: left;
  }

  .promotion-page-wrapper .listing-with-icon li img {
    width: 14px !important;
  }

  .promotion-page-wrapper .continue-btn {
    font-size: 13px;
    padding: 8px 20px;
  }

  .promotion-page-wrapper .content-wrapper {
    padding: 0 20px 0 30px !important;
  }

}

@media (max-width: 767px) {

  .promotion-page-wrapper .content-wrapper {
    padding: 0 !important;
  }

  .promotion-page-wrapper {
    background-image: url('./../../Assets/images/fullPageBg.png') !important;
    text-align: center;
  }

  .promotion-page-wrapper .content-wrapper-inner {
    padding: 30px 20px 30px 20px;
  }

  .promotion-page-wrapper .listing-with-icon {
    gap: 8px !important;
    max-width: max-content;
    width: 100%;
    margin: 0 auto;
  }

  .promotion-page-wrapper .listing-with-icon li {
    font-size: 14px !important;
  }

  .mobile-height-add .content-wrapper {
    max-height: initial !important;
  }

}

@media (max-width: 575px) {


  .promotion-page-wrapper .content-wrapper-inner {
    padding: 20px;
  }

  .promotion-page-wrapper .title {
    font-size: 14px;
  }

  .promotion-page-wrapper .sub-title {
    font-size: 15px;
  }

  .promotion-page-wrapper .sub-title-h4 {
    font-size: 12px;
    line-height: 1.4;
  }

  .promotion-page-wrapper .sub-title-h5 {
    font-size: 12px;
    line-height: 1;
  }

  .promotion-page-wrapper .listing-with-icon {
    gap: 5px !important;
  }

  .promotion-page-wrapper .listing-with-icon li {
    font-size: 12px !important;
  }

  .promotion-page-wrapper .listing-with-icon li img {
    width: 12px;
    margin: 0 5px 0 0;
  }

  .promotion-page-wrapper .continue-btn {
    font-size: 13px;
    padding: 4px 25px;
  }

}